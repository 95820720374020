import { Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { Button } from "components/shared/Button"
import { NoImageDiv } from "components/shared/NoImageDiv"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import { generateDateTerm } from "libs"
import { first } from "lodash"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { FC } from "react"
import styled from "styled-components"

export const EventContent:FC<{ eventForDisplay: EventCardType }> = ({ eventForDisplay }) => {
  const { name: placeName } = first(eventForDisplay?.event_places) || {}
  const { xs } = useBreakpoint()
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <div style={{ display: "flex", flexDirection: xs ? "column" : "row" }}>
      <div style={{ width: xs ? "100%" : "50%", height: xs ? 301 : 500 }}>
        {eventForDisplay.main_event_image_url ? (
          <img
            src={eventForDisplay.main_event_image_url}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        ) : (
          <div style={{ width: "100%", height: xs ? 301 : 500 }}>
            <NoImageDiv type="event" />
          </div>
        )}
      </div>
      <div
        style={{
          width: xs ? "100%" : "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: colors.black,
          padding: xs ? "40px 16px" : "60px 80px",
          height: xs ? 268 : 500
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <Typography.Text style={{ fontSize: 14, lineHeight: 1, color: "#f5f5f5" }}>
            {t("イベント")}
          </Typography.Text>
          <Typography.Title ellipsis={{ rows: xs ? 2 : 3 }} style={{ fontSize: xs ? 18 : 20, lineHeight: 1.5, letterSpacing: xs ? 0 : 0.8, fontWeight: xs ? "normal" : 400, color: colors.white }}>
            {eventForDisplay.title}
          </Typography.Title>
          <Typography.Text style={{ fontSize: 16, lineHeight: 1, color: colors.white }}>
            {generateDateTerm({ start: eventForDisplay.start_at, end: eventForDisplay.end_at, connectingText: "-", format: "YYYY年M月D日", formatExceptYear: "M月D日" })}
          </Typography.Text>
          {placeName && (
            <Typography.Text style={{ fontSize: 16, lineHeight: 1, color: colors.white }}>
              {placeName}
            </Typography.Text>
          )}
        </div>
        <StyledButton
          style={{ marginTop: xs ? 24 : 40, width: xs ? "100%" : "320px", height: 48, fontWeight: 400 }}
          onClick={() => router.push(urls.event(eventForDisplay.id))}
        >
          {t("詳細を見る")}
        </StyledButton>
      </div>
    </div>
  )
}

const StyledButton = styled(Button)`
  border: 1px solid ${colors.white};
  color: ${colors.white};
  :hover {
    background-color: ${colors.white};
    color: ${colors.black};
  }
`

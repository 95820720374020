import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import { ArticleCard, Entry } from "libs/contentfulForMagazine"
import { shuffle } from "lodash"
import ArrowNextIcon from "public/icons/arrow/next.svg"
import ArrowPrevIcon from "public/icons/arrow/prev.svg"
import { FC, useCallback, useMemo, useState } from "react"
import styled from "styled-components"

import { EventContent } from "./parts/EventContent"
import { MagazineContent } from "./parts/MagazineContent"

type Props ={
  events: EventCardType[]
  magazines: Entry<ArticleCard>[]
}

// マガジンはcontentfulから取得するため、fieldsを持っている
const isMagazine = (data: EventCardType | Entry<ArticleCard>): data is Entry<ArticleCard> => {
  return Object.hasOwn(data, "fields")
}

export const TopContent:FC<Props> = ({ events, magazines }) => {
  const [numberForDisplayEvent, setNumberForDisplayEvent] = useState(0)
  const eventOrMagazineArrayForDisplay = useMemo(() => shuffle([...events, ...magazines]), [events, magazines])
  const eventOrMagazineForDisplay = eventOrMagazineArrayForDisplay[numberForDisplayEvent]

  const { xs } = useBreakpoint()

  const handleClickPrev = useCallback(() => {
    if(numberForDisplayEvent === 0) {
      return setNumberForDisplayEvent(eventOrMagazineArrayForDisplay.length - 1)
    }
    setNumberForDisplayEvent(prev => prev - 1)
  }, [eventOrMagazineArrayForDisplay.length, numberForDisplayEvent])

  const handleClickNext = useCallback(() => {
    if(numberForDisplayEvent === eventOrMagazineArrayForDisplay.length - 1) {
      return setNumberForDisplayEvent(0)
    }
    setNumberForDisplayEvent(prev => prev + 1)
  }, [eventOrMagazineArrayForDisplay.length, numberForDisplayEvent])

  if(!eventOrMagazineForDisplay) return null

  return(
    <>
      {isMagazine(eventOrMagazineForDisplay) ? <MagazineContent magazineForDisplay={eventOrMagazineForDisplay} /> : <EventContent eventForDisplay={eventOrMagazineForDisplay} />}
      <div style={{ display: "flex", padding: xs ? "24px 16px" : "24px 64px 0px 64px", gap: "12px" }}>
        <div style={{ display: "flex", gap: "4px", flex: 1, alignItems: "center" }}>
          {eventOrMagazineArrayForDisplay.map((_event, index) => (
            <div
              key={index}
              style={{
                height: "4px",
                flex: 1,
                alignItems: "center",
                backgroundColor: index === numberForDisplayEvent ? "#656565" : "#D9D9D9"
              }}
              onClick={() => setNumberForDisplayEvent(index)}
            />
          ))}
        </div>
        <div style={{ display: "flex", gap: "8px" }}>
          <ArrowButton onClick={handleClickPrev}>
            <ArrowPrevIcon style={{ height: "20px", width: "20px", cursor: "pointer" }} />
          </ArrowButton>
          <ArrowButton onClick={handleClickNext}>
            <ArrowNextIcon style={{ height: "20px", width: "20px", cursor: "pointer" }} />
          </ArrowButton>
        </div>
      </div>
    </>
  )
}

const ArrowButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: 50%;
  padding: 13px;
  opacity: 1;
  font-size: 17px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: ${colors.black};
    border: 1px solid ${colors.white};
    color: ${colors.white};
  }
`
